import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';

import * as notFoundStyles from "../styles/pages/404.module.scss";

const NotFoundPage = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Astronaut.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <Layout>
      <div id={notFoundStyles.container}>
        <h1>{"This page doesn't exist"}</h1>
        <p>Seems like we got a bit lost</p>
        <div id={notFoundStyles.astronautContainer}>
          <GatsbyImage 
            image={query.file.childImageSharp.gatsbyImageData} 
            alt="Astronaut lost in space" 
          />
       
        </div>
        <p>
          <Link 
            id={notFoundStyles.homeButton}
            to="/"
          >
            Head back home
          </Link>
        </p> 
      </div>
    </Layout>
  );
};

export default NotFoundPage;
